import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SeO from "../components/seo"
import Header from "../components/header"
import Button from "../components/button"

import { css } from "@emotion/react"
import { MdToday, MdUpdate, MdExplore } from "react-icons/md"

const ArticleTemplate = (props) => {
    const lang = props.pageContext.lang
    const article = props.data.article
    const title = article.data.title.text
    const desc = article.data.excerpt
    const subtitle = article.data.category.document.data.title.text
    const rich_text = article.data.rich_text.html
    const thumb_image = article.data.image

    return (
        <Layout>
            <SeO 
                title={title}
                description={desc}
                lang={lang}
                slug={props.uri}
            />

            <Header 
                title={title}
                subtitle={subtitle}
                intro={desc}
                small
                indent={thumb_image.fluid ? true : false}
                lang={lang}
            />

            <div className="bg--white">
                {thumb_image.fluid &&
                    <section>
                        <div className="container content-indent--up">
                            <Img fluid={thumb_image.fluid} />
                        </div>
                    </section>
                }

                <article className="container" css={articleBlock}>
                    <div className="blog__meta"><MdToday /> Publié le {article.data.publication_date}. <MdUpdate /> Updaté le {article.last_publication_date}</div>
                    <div dangerouslySetInnerHTML={{ __html: rich_text }} className="rich-text" />
                </article>
            </div>

            <div className="container" style={{
                paddingTop: '50px',
                paddingBottom: '50px',
            }}>
                <Button link="/blog" text="Voir le blog" icon={<MdExplore />} />
            </div>
        </Layout>
    )
}

export default ArticleTemplate

const articleBlock = css`
    padding-top: 40px;
    padding-bottom: 70px;

    .blog__meta {
        font-size: .8rem;
        font-family: 'Courier Prime', Courier, monospace;
        margin-bottom: 10px;
        opacity: .5;

        svg {
            margin-bottom: -3px;
        }
    }
`

export const articleTemplateQuery = graphql`
    query ArticleTemplateQuery($slug: String!) {
        article: prismicArticles(uid: {eq: $slug}) {
            uid
            last_publication_date(
                formatString: "DD MMMM YYYY"
                locale: "fr"
            )
            data {
                title {
                    text
                }
                excerpt
                publication_date(
                    formatString: "DD MMMM YYYY"
                    locale: "fr"
                )
                category {
                    document {
                        ... on PrismicCategories {
                        uid
                        data {
                                title {
                                    text
                                }
                            }
                        }
                    }
                }
                rich_text {
                    html
                }
                image {
                    fluid(imgixParams: {q: 100}, maxWidth: 1000) {
                        ...GatsbyPrismicImageFluid
                    }
                }
            }
        }
    }
`